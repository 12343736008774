import Link from "next/link";
import { ReactNode } from "react";
import { Content } from "@prismicio/client";
import { SliceComponentProps } from "@prismicio/react";
import { LinkField } from "@prismicio/types";
import { Button } from "components/Button";
import { Image } from "lib/imgproxy";

/**
 * Props for `HeroSlice`.
 */
export type HeroSliceProps = SliceComponentProps<Content.HeroSliceSlice>;

/**
 * Component for "HeroSlice" Slices.
 */
const HeroSlice = ({ slice }: HeroSliceProps): JSX.Element => (
    <section
        data-slice-type={slice.slice_type}
        data-slice-variation={slice.variation}
        className="flex w-full flex-col items-center justify-between gap-8 lg:flex-row-reverse lg:gap-[94px]"
    >
        <div className="relative aspect-[1.345] h-fit w-full lg:h-[422px] lg:w-full">
            <Image
                alt={slice.primary.image.alt ?? ""}
                src={slice.primary.image.url ?? ""}
                unoptimized
                layout="fill"
                objectFit="contain"
                sizes="500px"
            />
        </div>
        <div className="flex flex-col items-center gap-10 lg:items-start">
            <div className="flex flex-col gap-5 text-center lg:text-start">
                <div className="typography-h2 lg:typography-h1 font-semibold text-blue-grey-900">
                    {slice.primary.title}
                </div>
                <div className="typography-main text-blue-grey">
                    {slice.primary.description}
                </div>
            </div>
            <div className="relative">
                <ActionBtn link={slice.primary.cta_link}>
                    {slice.primary.cta_text}
                </ActionBtn>
            </div>
        </div>
    </section>
);

const ActionBtn = ({
    children,
    link,
}: {
    children: ReactNode;
    link: LinkField;
}): JSX.Element => {
    if (!("url" in link) || typeof window === "undefined") return <div />;
    if (!link.url) return <div>{children}</div>;
    const href = new URL(link.url ?? "", window.location.origin);
    const isSameSite = href.host === window.location.host;
    if (isSameSite)
        return (
            <Link href={href} passHref>
                <Button className="px-[42px] py-3" asChild>
                    <a className="remove-styles-a">{children}</a>
                </Button>
            </Link>
        );
    return (
        <a href={link.url} target="_blank">
            <Button className="px-[42px] py-3">{children}</Button>
        </a>
    );
};

export default HeroSlice;
